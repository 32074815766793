import React from "react"

import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"
import SolidHero from "../../components/solid-hero/solid-hero"

const KlasseB196Page = ({ data }) => (
  <Layout>
    <SEO title="Klasse B196"
         description={"Genug vom Autofahren? Dann weg mit den zwei Stützrädern! Mach jetzt die B196er-Erweiterung ohne Prüfung und steig aufs Motorrad."}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={"Klasse B196"} primary title={"Autoführerschein"}
               subtitle={() => <>Klasse B196</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview
      text={"Die 196er-Erweiterung für den Autoführerschein ist perfekt für alle, denen vier Räder nicht mehr genug sind – und die deshalb auch gerne mal auf zwei umsteigen möchten. Genauer gesagt auf den Fuhrpark der Klasse A1: Fahrzeuge mit maximal 125 cm³ sowie einem Maximum von 15 PS. Als ausdrückliche Erweiterung des B-Führerscheins brauchst du hier übrigens weder für eine theoretische, noch für eine praktische Prüfung pauken. Die 196er ist erst ab einem Mindestalter von 25 Jahren sowie mindestens fünf Jahren Klasse B-Führerschein-Besitzes drin. Ohne Fahrstunden gibt’s die Bescheinigung natürlich trotzdem nicht. Bevor du also dann allein auf deinem Bike Deutschlands Straßen (un)sicher machen kannst, sehen wir uns für einige Theorie- und Praxisstunden. Die sind aber Vollgas auf die Motorrad-Thematik ausgerichtet und behandeln genau das, was für dich interessant wird. Wenn dir der PKW nicht mehr reicht, warten hier ein völlig neues Fahrgefühl sowie ein hochmotiviertes Team auf dich!"}>
      <ClassOverviewItem title={"Ausbildung"}>
        <li>Ausbildung: Theorie</li>
        <li>Prüfung: keine</li>
        <li>Vorbesitz: Klasse B</li>
        <li>Erweiterung des Führerscheins der Klasse B um die Schlüsselzahl 196</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Stundenpensum"}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Praxisstunden: 5</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 4</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Vorraussetzungen"}>
        <li>Mindestalter: 25 Jahre</li>
        <li>Besitz des Führerscheins der Klasse B seit mindestens 5 Jahren</li>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Merkmale"}>
        <li>Berechtigt zum Fahren von Leichtkrafträdern der Motorradklasse A1
          (z. B. 125er-Maschine mit bis zu 15 PS)
        </li>
        <li>Berechtigung gilt nur innerhalb Deutschlands</li>
        <li>Es handelt sich nicht um einen Führerschein der Klasse A1 und somit ist auch keine Aufstiegsregelung (wie z.
          B. die Erweiterung auf die Klasse A2) möglich. Für größere Maschinen muss ein entsprechender
          Führerscheinerwerb erfolgen.
        </li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Weitere Infos"} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseB196Page


export const query = graphql`
  query KlasseB196PageQuery {
    file(relativePath: { eq: "klasse-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
